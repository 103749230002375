import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link, NavLink } from "react-router-dom";
import logo from './img/logo.png';
import Dropdown from './components/Dropdown';
import Notifications from './Notifications';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUsersCog, faSignOutAlt, faSearch } from '@fortawesome/free-solid-svg-icons'
import './scss/Header.scss';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import history from './history';
import classNames from 'classnames';


const MenuLink = ({ children }) => (
    <li className="nav-item px-3 d-flex align-self-center">
        {children}
    </li>
);

const DoSearchQuery = (e) => {
    e.preventDefault();
    const val = e.target[0].value;
    if (!val) {
        return
    }
    history.push(`/search/${val}`);
}

function Header({ userData }) {
    const [showCollapsedMenu, setShowCollapsedMenu] = useState(false);

    return (
        <header>
            <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
                <Link className="navbar-brand" to="/"><img src={logo} alt="Block.co" style={{ 'width': '200px' }} /></Link>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" onClick={() => setShowCollapsedMenu(!showCollapsedMenu)}>
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className={classNames("collapse navbar-collapse", { show: showCollapsedMenu })} id="navbarSupportedContent">
                    <form className="form-inline my-2 my-lg-0" onSubmit={DoSearchQuery}>
                        <input id="search_input" className="form-control" type="search" placeholder="Search" aria-label="Search" />
                        <button id="search_button" className="btn btn-primary my-2 my-sm-0" type="submit"><FontAwesomeIcon icon={faSearch} /></button>
                    </form>
                    <ul className="navbar-nav ml-auto">
                        <MenuLink>
                            <NavLink exact to="/" className="nav-link">Dashboard</NavLink>
                        </MenuLink>
                        {userData.client.has_pk_ownership !== true &&
                            <MenuLink>
                                <NavLink exact to="/issue/" id="new_issuance_header" className="nav-link">New Issuance</NavLink>
                            </MenuLink>
                        }
                        <MenuLink>
                            <NavLink exact to="/issuances/" className="nav-link">Issuances</NavLink>
                        </MenuLink>
                        <MenuLink>
                            <span id="notifications_link" className="nav-link cursor-pointer"><Notifications /></span>
                        </MenuLink>
                        <Dropdown
                            component="li"
                            className="nav-item px-3 d-flex align-self-center"
                            buttonClassName="btn btn-link nav-link"
                            button={userData ?
                                <div id="admin_dropdown" className="d-inline-block">
                                    <div className="text-center">
                                        {userData.username}
                                    </div>
                                    <div className="text-center">
                                        <small className="font-weight-light">
                                            {userData.client && userData.client.name}
                                        </small>
                                    </div>
                                </div> :
                                null
                            }
                        >
                            <Link className="dropdown-item" to="/account/"><FontAwesomeIcon icon={faUsersCog} fixedWidth /> Account settings</Link>
                            <Link className="dropdown-item" to="/help/issuing/"><FontAwesomeIcon icon={faQuestionCircle} fixedWidth /> Help</Link>
                            <div className="dropdown-divider"></div>
                            <Link id="logout_link" className="dropdown-item" to="/logout/"><FontAwesomeIcon icon={faSignOutAlt} fixedWidth /> Logout</Link>
                        </Dropdown>
                    </ul>
                </div>
            </nav>
        </header>
    )
}


export default connect(
    state => ({
        userData: state.auth.user.userData,
    })
)(Header);
